import styles from "./TableWithDots.module.scss";

interface TableWithDotsProps {
  columns: string[];
  rows: { title: string; dots: number[] }[];
}

const TableWithDots = ({columns, rows}: TableWithDotsProps) => {
  const { roleTable, cell, dot, wrapper } = styles;
  return (
    <div className={wrapper}>
      <table className={roleTable}>
        <tbody>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
          {rows.map(({ title, dots }, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {columns.map((_, index) => (
                  <td key={`${rowIndex}-cell-${index}`}>
                    {index === 0 && <div className={cell}>{title}</div>}
                    {dots.includes(index) && <div className={dot} />}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableWithDots;
