import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { closeModal } from "../../../reducers/modalReducer";
import { Button } from "@almafintech/react-components/Button";
import { Alert, Modal } from "@almafintech/react-components";
import useWindowWidth from "../../../hooks/useWindowWidth";

import styles from "./MinimumRequirementsModal.module.scss";

const MICROSOFT_EDGE_URL =
  "https://www.microsoft.com/es-es/edge/download?form=MA13FJ&ch=1";
const CHROME_URL =
  "https://support.google.com/chrome/answer/95346?hl=es&co=GENIE.Platform%3DDesktop";
const SAFARI_URL = "https://www.apple.com/la/safari/";
const FIREFOX_URL = "https://www.mozilla.org/es-AR/firefox/new/";

const requirements = [
  {
    title: "Cámara",
    items: [
      "Resolución: 320x240 píxeles",
      "Tamaño: 4 pulgadas",
      "Cuadros por segundo (FPS): 15",
      "Tasa de refresco: 60Hz",
      "Distinción de colores: sí",
      "Cámara virtual o software: no",
    ],
    infoAlertMessage: "Si es webcam, montala encima de la pantalla.",
  },
  {
    title: "Buscador de internet",
    items: [
      <a href={CHROME_URL} target="_blank">
        Google Chrome
      </a>,
      <a href={FIREFOX_URL} target="_blank">
        Mozilla Firefox
      </a>,
      <a href={MICROSOFT_EDGE_URL} target="_blank">
        Microsoft Edge
      </a>,
      <a href={SAFARI_URL} target="_blank">
        Safari
      </a>,
    ],
    infoAlertMessage:
      "Actualizá tu buscador a su última versión para asegurar que funcione correctamente.",
  },
];

const MinimumRequirementsModal = () => {
  const {
    container,
    header,
    requirement,
    alert,
    modalBaseMobile,
    modalBackDropMobile,
  } = styles;

  const dispatch = useAppDispatch();
  const { modalName } = useAppSelector((state) => state.modal);

  const handleClose = () => dispatch(closeModal());

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  return (
    <Modal
      isOpen={modalName === "modal-minimum-requirements"}
      width={isMobile ? "100%" : "520px"}
      hideCloseButton={isMobile}
      onClose={handleClose}
      className={container}
      {...(isMobile
        ? {
            classNames: {
              base: modalBaseMobile,
              backdrop: modalBackDropMobile,
            },
          }
        : undefined)}
      header={
        <header className={header}>
          <h1>Requisitos mínimos</h1>
          <p>
            Para validar tu identidad, la cámara y buscador de internet deben
            cumplir estas condiciones.
          </p>
        </header>
      }
      footer={
        <Button
          variant="primary"
          text={isMobile ? "Volver" : "Cerrar"}
          fullWidth={isMobile}
          type="button"
          onClick={handleClose}
        />
      }
    >
      <section>
        {requirements.map(({ title, items, infoAlertMessage }) => (
          <article className={requirement} key={title}>
            <h3>{title}:</h3>
            <ul key={`${title}-list`}>
              {items.map((item, index) => (
                <li key={`${title}-item-${index}`}>{item}</li>
              ))}
            </ul>
            <Alert variant="INFO" className={alert} key={`${title}-info`}>
              {infoAlertMessage}
            </Alert>
          </article>
        ))}
      </section>
    </Modal>
  );
};

export default MinimumRequirementsModal;
