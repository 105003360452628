import { ReactNode, useEffect, useRef } from "react";

interface Props {
  children: ReactNode;
  onClick: () => void;
}

const ClickAwayListener = ({ children, onClick }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onClick();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return <div ref={ref}>{children}</div>;
};

export default ClickAwayListener;
