import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeModal } from "../../reducers/modalReducer";
import { Modal } from "@almafintech/react-components/Modal";
import { Button } from "@almafintech/react-components/Button";
import { deleteIndividualDraft } from "../../connectors/connectors";
import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { showToastMessage } from "@almafintech/react-components";
import { genericToastifyError } from "../../utils";
import { useState } from "react";
import { FormikProps, FormikValues } from "formik";

import styles from "./EditDNIModal.module.scss";

interface EditDNIModalProps {
  formik: FormikProps<FormikValues>;
}

const EditDNIModal = ({ formik }: EditDNIModalProps) => {
  const { headerTitle, wrapper, modalBaseMobile, modalBackDropMobile } = styles;

  const [confirmLoading, setConfirmLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { modalName } = useAppSelector((state) => state.modal);
  const { draftUUID } = useAppSelector((state) => state.onboardingData);

  const isMobile = window.innerWidth < 768;

  const handleClose = () => dispatch(closeModal());

  const handleConfirm = async () => {
    try {
      setConfirmLoading(true);

      await deleteIndividualDraft({ draftUUID });

      //Delete dniImages and livenessValidation documents
      const db = getFirestore();
      const docFilesRef = doc(db, "dniImages", draftUUID);
      const docLivenessRef = doc(db, "livenessValidation", draftUUID);

      await deleteDoc(docFilesRef);
      await deleteDoc(docLivenessRef);

      dispatch({ type: "reset-store" });

      formik.resetForm();
    } catch (error) {
      showToastMessage(genericToastifyError, {
        containerId: "layoutPage",
        type: "error",
      });
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <Modal
      isOpen={modalName === "modal-edit-dni"}
      onClose={handleClose}
      hideCloseButton={isMobile}
      width={isMobile ? "100%" : "520px"}
      header={<h1 className={headerTitle}>¿Querés editar tu DNI?</h1>}
      footer={
        <>
          <Button
            isLoading={confirmLoading}
            text="Confirmar"
            onClick={handleConfirm}
            fullWidth={isMobile}
          />
          <Button
            variant="secondary"
            text="Cancelar"
            onClick={handleClose}
            fullWidth={isMobile}
          />
        </>
      }
      {...(isMobile
        ? {
            classNames: {
              base: modalBaseMobile,
              backdrop: modalBackDropMobile,
            },
          }
        : undefined)}
    >
      <div className={wrapper}>
        <p>
          Para editar tu DNI, deberás corregir tu CUIT / CUIL.
          <br />
          Esto reiniciará el proceso de registro. Asegurate de que realmente
          querés hacerlo antes de continuar.
        </p>
      </div>
    </Modal>
  );
};

export default EditDNIModal;
