import { createSlice } from "@reduxjs/toolkit";
import { GenderInitials } from "../types";

export type Nullable<T> = {
  [P in keyof T]: T[P] extends object ? Nullable<T[P]> | null : T[P] | null;
};

interface RenaperData {
  number: string;
  gender: GenderInitials;
  names: string;
  lastNames: string;
  birthdate: string;
  copy: string; //CHEQUEAR EJEMPLARES
  expirationDate: string;
  creationDate: string;
  cuil: string;
  streetAddress: string;
  numberStreet: string;
  floor?: string | number;
  department?: string;
  zipCode: string;
  city: string;
  municipality: string;
  province: string;
  country: string;
  messageOfDeath?: string;
  nationality: string;
  countryBirth: string;
}
const initialState: Nullable<RenaperData> = {
  number: "",
  gender: null,
  names: "",
  lastNames: "",
  birthdate: "",
  copy: "",
  expirationDate: "",
  creationDate: "",
  cuil: "",
  streetAddress: "",
  numberStreet: "",
  floor: "",
  department: "",
  zipCode: "",
  city: "",
  municipality: "",
  province: "",
  country: "",
  messageOfDeath: "",
  nationality: "",
  countryBirth: "",
};

const renaperSlice = createSlice({
  name: "renaper",
  initialState,
  reducers: {
    updateRenaperData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateRenaperData } = renaperSlice.actions;

export default renaperSlice.reducer;
