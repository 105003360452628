import { motion } from "framer-motion";
import { useState } from "react";

import ClickAwayListener from "../ClickAwayListener/ClickAwayListener";

import supportIcon from "../../assets/images/icons/ui/support.svg";
import whatsappIcon from "../../assets/images/icons/ui/whatsapp.svg";
import emailIcon from "../../assets/images/icons/ui/email.svg";

import styles from "./SupportButton.module.scss";
import { MAIL, WHATSAPP_LINK } from "../../utils";

interface Props {
  className: string;
}

const SupportButton = ({ className }: Props) => {
  const { supportButton, supportOptions, supportOption } = styles;

  const [showSupportOptions, setShowSupportOptions] = useState<boolean>(false);

  return (
    <ClickAwayListener onClick={() => setShowSupportOptions(false)}>
      <motion.div
        key="supportOptions"
        initial={{ y: "100%" }}
        animate={{ y: "0%" }}
        transition={{ duration: 1 }}
        className={`${supportButton} ${className}`}
        onClick={() => setShowSupportOptions((prev) => !prev)}
      >
        {showSupportOptions && (
          <div className={supportOptions}>
            <a href={`mailto:${MAIL}`} className={supportOption}>
              <img src={emailIcon} alt="Email" />
            </a>
            <a
              href={WHATSAPP_LINK}
              target="_blank"
              rel="noreferrer"
              className={supportOption}
            >
              <img src={whatsappIcon} alt="Whatsapp" />
            </a>
          </div>
        )}

        <img src={supportIcon} alt="Soporte" />
      </motion.div>
    </ClickAwayListener>
  );
};

export default SupportButton;
