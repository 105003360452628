import { useRef, useEffect, useState } from "react";
import {
  TokenCard,
  TokenCardRef,
} from "@almafintech/react-components/TokenCard";
import {
  onUpdatePrimaryButton,
  onUpdateSecondaryButton,
} from "../../reducers/buttonsReducer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { authToken, regenerateToken } from "../../connectors/connectors";
import { updateToken } from "../../reducers/authReducer";
import { getDraftUUIDFromToken } from "../../app/utils";
import { onUpdateDraftUUID } from "../../reducers/onboardingDataReducer";
import { FormikProps, FormikValues } from "formik";
import useForm from "../../hooks/useForm";
import { getCompanyData } from "../../utils";

// Mask email 3 last characters after the @
const maskEmail = (email: string) => {
  const [user, domain] = email.split("@");
  return `${user.slice(0, -3)}***@${domain}`;
};

const minutesToMiliseconds = (minutes: number) => {
  return minutes * 60 * 1000;
};

interface TokenStepProps {
  onSuccess: () => Promise<void>;
  onAuthorize: (draftUUID?: string) => Promise<void>;
  previousStep: number;
  formik: FormikProps<FormikValues>;
}
const TokenStep = ({
  onSuccess,
  onAuthorize,
  previousStep,
  formik,
}: TokenStepProps) => {
  const tokenCardRef = useRef<TokenCardRef>(null);
  const dispatch = useAppDispatch();
  const { handleBack } = useForm(formik);
  const { signatureRequestId } = useAppSelector(
    (state) => state.authentication
  );
  const onboardingData = useAppSelector((state) => state.onboardingData);

  const {
    stepData: { step },
    formValues,
  } = onboardingData;

  const [loading, setLoading] = useState(false);
  const [lastTokenSent, setLastTokenSent] = useState<number>(Date.now());

  const handleAuthorize = () => {
    if (tokenCardRef.current) {
      tokenCardRef?.current?.authorizeToken();
    }
  };

  useEffect(() => {
    dispatch(
      onUpdatePrimaryButton({
        onClick: handleAuthorize,
        text: "Continuar",
        type: "button",
        disabled: loading,
        isLoading: loading,
        show: true,
      })
    );
    if (previousStep)
      dispatch(
        onUpdateSecondaryButton({
          text: "Volver",
          disabled: false,
          isLoading: false,
          onClick: handleBack,
          show: true,
        })
      );
  }, [loading, previousStep]);

  useEffect(() => {
    dispatch(
      onUpdatePrimaryButton({
        onClick: handleAuthorize,
        text: "Continuar",
        type: "button",
        disabled: true,
        isLoading: false,
        show: true,
      })
    );
    if (previousStep)
      dispatch(
        onUpdateSecondaryButton({
          text: "Volver",
          disabled: false,
          isLoading: false,
          onClick: handleBack,
          show: true,
        })
      );
  }, []);

  return (
    <TokenCard
      ref={tokenCardRef}
      title="Validá tu dirección de mail"
      subtitle="El código es válido durante 24 horas"
      autoSendToken
      lastSendToken={lastTokenSent + minutesToMiliseconds(2)}
      onAuthorize={async (token) => {
        setLoading(true);
        try {
          if (!signatureRequestId)
            throw new Error("Error: signatureRequestId not found");

          const authResponse = await authToken({
            email: formValues?.email,
            signatureRequestId,
            token: token,
          });

          dispatch(updateToken(authResponse.data.token));

          const draftUUID = getDraftUUIDFromToken({
            token: authResponse.data.token,
          });

          dispatch(onUpdateDraftUUID(draftUUID));

          await (onAuthorize && onAuthorize(draftUUID));

          return {
            success: true,
          };
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          setLoading(false);
          if (error.response?.data?.code === "INVALID_TOKEN") {
            return {
              success: false,
              message:
                "El token ingresado es incorrecto. Verificalo e intentá nuevamente.",
            };
          } else if (error.response?.data?.code === "EXPIRED_TOKEN") {
            return {
              success: false,
              message:
                "El token ha expirado. Solicitá uno nuevo para continuar.",
            };
          } else if (error.response?.data?.code === "MAX_ATTEMPTS_REACHED") {
            return {
              success: false,
              message:
                "Superaste el número máximo de intentos permitidos. Por seguridad, generá un nuevo token.",
            };
          } else
            return {
              success: false,
              message: "Error al autorizar el token.",
            };
        }
      }}
      onResend={async () => {
        if (!signatureRequestId || !formValues?.email) return;

        try {
          await regenerateToken({
            email: formValues?.email,
            signatureRequestId,
            companyType: getCompanyData().companyName,
          });

          setLastTokenSent(Date.now());
        } catch (error) {
          console.error(error);
        }
      }}
      onSuccess={() => {
        if (!step) return;
        onSuccess && onSuccess();
        setLoading(false);
      }}
    >
      Ingresá el token que enviamos a {maskEmail(formValues?.email)}
    </TokenCard>
  );
};

export default TokenStep;
