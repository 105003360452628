import { FormEventHandler, useEffect, useRef } from "react";
import styles from "./TemplateInterpreter.module.scss";
import { BasicStep } from "../../types";
import { FormikProps, FormikValues } from "formik";
import TemplateInputSelector from "./TemplateInputSelector";

interface TemplateInterpreterFormProps {
  templateLoaded: boolean;
  onSubmit: FormEventHandler<HTMLFormElement> | undefined;
  currentStepData: BasicStep | undefined;
  formik: FormikProps<FormikValues>;
}

const TemplateInterpreterForm = ({
  onSubmit,
  templateLoaded,
  currentStepData,
  formik,
}: TemplateInterpreterFormProps) => {
  const { formContainer } = styles;

  const formContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      if (formContainerRef.current) {
        formContainerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  }, [currentStepData, formContainerRef]);

  return (
    <form onSubmit={onSubmit}>
      {templateLoaded && (
        <div className={formContainer} ref={formContainerRef}>
          {currentStepData?.fields?.map((field) => (
            <TemplateInputSelector
              currentStepFields={currentStepData.fields}
              key={field.name}
              field={field}
              formik={formik}
            />
          ))}
        </div>
      )}
    </form>
  );
};

export default TemplateInterpreterForm;
