import { createSlice } from "@reduxjs/toolkit";

interface Validation {
  name: string;
  externalCode: string;
}
const initialState: Validation[] = [];

const validationsSlice = createSlice({
  name: "validations",
  initialState,
  reducers: {
    addOneValidation: (state, action) => {
      const filteredState = state.filter(
        (validation) => validation.name !== action.payload.name
      );
      filteredState.push(action.payload);
      return filteredState;
    },
    replaceValidations: (_, action) => {
      return action.payload;
    },
  },
});

export const { addOneValidation, replaceValidations } =
  validationsSlice.actions;

export default validationsSlice.reducer;
