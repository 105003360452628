import styles from "./PEPModal.module.scss";

const PEPText = () => {
  const { content } = styles;

  return (
    <div className={content}>
      <p>Ciudad de Buenos Aires, 28/02/2023</p>

      <p>
        VISTO el Expediente Nº EX-2023-05018567--APN-DD#UIF, las Leyes Nros.
        23.298, 23.660 y sus respectivas modificatorias, 24.759, 25.188 y sus
        modificatorias, 25.246 y sus modificatorias, 25.319, 26.097 y 26.215 y
        sus modificatorias, los Decretos Nros. 290 del 27 de marzo de 2007 y sus
        modificatorios, 652 y 653 ambos del 22 de septiembre de 2022, la
        Resolución UIF N° 134 del 23 de noviembre de 2018 y sus modificatorias,
        y
      </p>

      <p>CONSIDERANDO:</p>

      <p>
        Que conforme a lo dispuesto por los artículos 5° y 6° de la Ley N°
        25.246 y sus modificatorias, la UNIDAD DE INFORMACIÓN FINANCIERA es un
        organismo que funciona con autonomía y autarquía financiera en
        jurisdicción del MINISTERIO DE ECONOMÍA y tiene a su cargo el análisis,
        tratamiento y transmisión de información a los efectos de prevenir e
        impedir el Lavado de Activos (LA) y la Financiación del Terrorismo (FT).
      </p>

      <p>
        Que, por su parte, el artículo 20 de la citada Ley, establece y enumera
        los Sujetos Obligados a informar ante esta UNIDAD DE INFORMACIÓN
        FINANCIERA, en los términos de los artículos 21 y 21 bis del mismo
        cuerpo legal, mientras que el artículo 20 bis define el contenido de ese
        deber.
      </p>

      <p>
        Que, la UNIDAD DE INFORMACIÓN FINANCIERA ha emitido, en uso de las
        facultades establecidas por el inciso 10 del artículo 14 de la ley
        mencionada, directivas, instrucciones y resoluciones respecto de las
        medidas que deben aplicar los Sujetos Obligados para, entre otras
        obligaciones, identificar y conocer a sus clientes.
      </p>

      <p>
        Que la República Argentina es miembro pleno del GRUPO DE ACCIÓN
        FINANCIERA INTERNACIONAL (GAFI) desde el año 2000, organismo
        intergubernamental cuyo propósito es el desarrollo y la promoción de
        estándares internacionales para combatir el Lavado de Activos, la
        Financiación del Terrorismo y la Proliferación de Armas de Destrucción
        Masiva (LA/FT/FP), y como tal debe ajustar las normas legales y
        regulatorias a sus recomendaciones, las cuales constituyen los
        estándares internacionales más reconocidos para combatir dichos delitos.
      </p>

      <p>
        Que la Recomendación 1 del GAFI establece que los países deben
        identificar, evaluar y entender sus riesgos de LA/FT, a los efectos de
        destinar recursos para asegurar que se mitiguen eficazmente los riesgos,
        mediante un enfoque basado en riesgo, a fin de asegurar que las medidas
        para prevenirlos o mitigarlos sean proporcionales a los riesgos
        identificados.
      </p>

      <p>
        Que, en ese marco, mediante el Decreto N° 653/22, se aprobó la Primera
        Evaluación Nacional de Riesgos de Lavado de Activos y, a través del
        Decreto N° 652/22, la actualización de la Evaluación Nacional de Riesgos
        de Financiación del Terrorismo y la Proliferación de Armas de
        Destrucción Masiva.
      </p>

      <p>
        Que, de acuerdo con la mencionada Recomendación 1, el enfoque basado en
        riesgo debe constituir un fundamento esencial para la asignación eficaz
        de recursos en todo el régimen de prevención del LA/FT y la
        implementación de medidas basadas en riesgo en todas las Recomendaciones
        del GAFI.
      </p>

      <p>
        Que conforme las Recomendaciones 12 y 22 del GAFI, los estándares
        internacionales postulan que se debe exigir a los Sujetos Obligados,
        Instituciones Financieras y Actividades y Profesiones No Financieras
        Designadas (APNFD) la identificación y la aplicación de las medidas de
        mitigación de riesgos de LA/FT, correspondientes, respecto de las
        personas que revisten la condición de Persona Expuesta Políticamente
        (PEP).
      </p>

      <p>
        Que dichas Recomendaciones exigen que los países garanticen que tanto
        las Instituciones Financieras como las APNFD, implementen medidas para
        evitar el uso indebido del sistema financiero y no financiero por parte
        de las personas que revisten la condición de PEP, así como detectar el
        abuso que pudiera ocurrir.
      </p>

      <p>
        Que, por otra parte, el GAFI ha emitido en el año 2013 una Guía acerca
        de las Personas Expuestas Políticamente mediante la que define, en
        consonancia con lo dispuesto en la Recomendación 12, que revisten tal
        carácter aquellas personas a quienes se les ha confiado una función
        pública, a los miembros de la familia y/o aquellas personas allegadas a
        una PEP.
      </p>

      <p>
        Que bajo dicho marco normativo la UNIDAD DE INFORMACIÓN FINANCIERA dictó
        la Resolución N° 134/18, mediante la cual aprobó una nueva regulación
        para los Sujetos Obligados enumerados en el artículo 20 de la Ley N°
        25.246, respecto al tratamiento de las Personas Expuestas Políticamente
        con el objetivo de fortalecer las medidas de debida diligencia
        aplicables a las relaciones con esas personas y basar las obligaciones y
        medidas de debida diligencia en un enfoque basado en riesgo,
        actualizando a tal fin la nómina de condiciones que deben reunir los
        individuos considerados PEP.
      </p>

      <p>
        Que además de las Recomendaciones del GAFI, para el dictado de la
        Resolución mencionada precedentemente, se tuvo en cuenta la “Convención
        Interamericana contra la Corrupción” adoptada por la Organización de los
        Estados Americanos (OEA), aprobada por la Ley N° 24.759; la “Convención
        de las Naciones Unidas contra la Corrupción” adoptada en la ciudad de
        Nueva York, Estados Unidos de América, el 31 de octubre de 2003,
        aprobada por la Ley N° 26.097; la “Convención para Combatir el Cohecho
        de Funcionarios Públicos Extranjeros en Transacciones Comerciales
        Internacionales” suscripta en la ciudad de París, República Francesa, el
        17 de diciembre de 1997, aprobada por la Ley Nº 25.319; las
        recomendaciones de la Organización para la Cooperación y el Desarrollo
        Económicos (OCDE) emitidas en el mes de marzo de 2017, en relación a la
        implementación de la citada Convención para Combatir el Cohecho, en el
        marco de la Fase 3 bis del proceso de membresía de la República
        Argentina; el artículo 5º de la Ley de Ética en el Ejercicio de la
        Función Pública Nº 25.188, atendiendo a la relevancia de las funciones,
        el carácter de servicio público y el interés público comprometido con
        respecto a la nómina de cargos públicos o funciones que integran la
        condición de PEP.
      </p>

      <p>
        Que, en atención al tiempo transcurrido y la experiencia recogida en la
        aplicación de la Resolución UIF N° 134/18, se considera conveniente
        actualizar su contenido dado que ciertas disposiciones no responden,
        estrictamente, al enfoque basado en riesgo que las citadas
        recomendaciones mandan acatar. Que, en tal sentido, con el propósito de
        otorgar previsibilidad y mayor seguridad jurídica a la figura de una
        Persona Expuesta Políticamente resulta necesario precisar un plazo donde
        cese tal condición. Que en ese sentido, la condición de PEP, cesará
        luego de transcurrido DOS (2) años, desde que la persona dejó de cumplir
        la función o cargo público correspondiente. Sin embargo, una vez
        cumplido el plazo de los DOS (2) años, el Sujeto Obligado deberá evaluar
        la situación del cliente o beneficiario final mediante un enfoque basado
        en riesgo, tomando en consideración la relevancia de la función
        desempeñada, la potestad de disposición y/o administración de fondos y
        la antigüedad en la función pública ejercida, entre otros factores de
        relevancia para el análisis del nivel de riesgo.
      </p>

      <p>
        Que, asimismo, se considera oportuno brindar mayor certidumbre respecto
        al alcance de los vínculos de cercanía o afinidad con una PEP
        determinantes para que la persona cercana o afín asuma esa condición.
      </p>

      <p>
        Que en consonancia con la Recomendación 12 del GAFI, los Sujetos
        Obligados deben: a) obtener, en caso de que corresponda, la aprobación
        del Oficial de Cumplimiento para iniciar las relaciones comerciales, o
        mantener las mismas con este tipo de clientes y sus beneficiarios
        finales en aquellos casos donde ya existe una relación comercial y
        modifican su condición de PEP; b) adoptar las medidas razonables para
        poder establecer el origen de los fondos y del patrimonio; y c) adoptar
        las medidas de Debida Diligencia Reforzadas, que disponga la regulación
        específica vigente para cada Sujeto Obligado, en relación con este tipo
        de cliente y realizar el monitoreo continuado de la relación comercial.
      </p>

      <p>
        Que, a su vez, corresponde establecer que, además de que el Sujeto
        Obligado tome las medidas razonables para determinar si el cliente es
        una PEP, lo debe realizar con respecto a los beneficiarios finales, tal
        como lo prevé la mencionada Recomendación 12, apartado a), del GAFI.
      </p>

      <p>
        Que, con respecto a la suscripción de la declaración jurada mediante la
        cual se requiere a los clientes que manifiesten si revisten o no la
        condición de PEP, corresponde que dicho instrumento sea suscripto no
        sólo al momento del inicio de la relación comercial, sino también al
        momento de cambiar la condición de PEP (sea que empiece a revestir tal
        carácter o deje de serlo).
      </p>

      <p>
        Que, de esta manera, se subsana una deficiencia de la resolución actual,
        toda vez que ella, si bien no prevé que los Sujetos Obligados deban
        informar la condición de PEP de los beneficiarios finales, sí exige que
        verifiquen, en todos los casos, y con un enfoque basado en riesgo, la
        condición de PEP respecto de sus clientes y sus beneficiarios finales.
      </p>

      <p>
        Que en el proceso de redacción de la presente norma han sido consultados
        los organismos específicos de control, conforme a lo previsto en el
        inciso 10 del artículo 14 de la Ley N° 25.246 y sus modificatorias.
      </p>

      <p>
        Que la Dirección de Asuntos Jurídicos de esta Unidad ha emitido la
        opinión de su competencia.
      </p>

      <p>
        Que el Consejo Asesor de esta Unidad ha tomado intervención, de acuerdo
        con lo previsto en el artículo 16 de la Ley Nº 25.246 y sus
        modificatorias.
      </p>

      <p>
        Que la presente se dicta en ejercicio de las facultades conferidas por
        la Ley Nº 25.246 y sus modificatorias y el Decreto N° 290/07 y sus
        modificatorios.
      </p>

      <p>Por ello,</p>

      <p>EL PRESIDENTE DE LA UNIDAD DE INFORMACIÓN FINANCIERA</p>

      <p>RESUELVE:</p>

      <p>ARTÍCULO 1º.- PERSONAS EXPUESTAS POLÍTICAMENTE EXTRANJERAS.</p>

      <p>
        Son consideradas Personas Expuestas Políticamente Extranjeras los
        funcionarios públicos pertenecientes a países extranjeros que se
        desempeñen o se hayan desempeñado en alguna de las siguientes funciones:
      </p>

      <p>
        a) Jefe de Estado, Jefe de Gobierno, Gobernador, Intendente, Ministro,
        Secretario de Estado u otro cargo gubernamental equivalente.
      </p>

      <p>
        b) Miembro del Parlamento, Poder Legislativo o de otro órgano de
        naturaleza equivalente.
      </p>

      <p>
        c) Juez o Magistrado de Tribunales Superiores u otra alta instancia
        judicial o administrativa, en el ámbito del Poder Judicial.
      </p>

      <p>d) Embajador o cónsul de un país u organismo internacional.</p>

      <p>
        e) Autoridad, apoderado, integrante del órgano de administración o
        control dentro de un partido político extranjero.
      </p>

      <p>
        f) Oficial de alto rango de las Fuerzas Armadas (a partir de coronel o
        grado equivalente en la fuerza y/o país de que se trate) o de las
        fuerzas de seguridad pública (a partir de comisario o rango equivalente
        según la fuerza y/o país de que se trate).
      </p>

      <p>
        g) Miembro de los órganos de dirección y control de empresas de
        propiedad estatal.
      </p>

      <p>
        h) Miembro de los órganos de dirección o control de empresas de
        propiedad privada o mixta cuando el Estado posea una participación igual
        o superior al VEINTE POR CIENTO (20%) del capital o del derecho a voto,
        o que ejerza de forma directa o indirecta el control de dicha entidad.
      </p>

      <p>
        i) Presidente, vicepresidente, director, gobernador, consejero, síndico
        o autoridad equivalente de bancos centrales y otros organismos de
        regulación y/o supervisión del sector financiero.
      </p>

      <p>
        j) Representantes consulares, miembros de la alta gerencia, como son los
        directores y miembros de la junta, o cargos equivalentes, apoderados y
        representantes legales de una organización internacional, con facultades
        de decisión, administración o disposición.
      </p>

      <p>
        (Artículo sustituido por art. 1° de la Resolución N° 192/2024 de la
        Unidad de Información Financiera B.O. 10/12/2024. Vigencia: a partir de
        su publicación en el BOLETÍN OFICIAL.)
      </p>

      <p>
        ARTÍCULO 2º.- PERSONAS EXPUESTAS POLÍTICAMENTE NACIONALES, PROVINCIALES,
        MUNICIPALES O DE LA CIUDAD AUTÓNOMA DE BUENOS AIRES.
      </p>

      <p>
        Son consideradas Personas Expuestas Políticamente nacionales,
        provinciales, municipales o de la Ciudad Autónoma de Buenos Aires, los
        funcionarios públicos de dichas jurisdicciones que se desempeñen o se
        hayan desempeñado en alguno de los siguientes cargos:
      </p>

      <p>a) Presidente o Vicepresidente de la Nación.</p>

      <p>
        b) Legislador nacional, provincial, municipal o de la Ciudad Autónoma de
        Buenos Aires.
      </p>

      <p>
        c) Gobernador, Vicegobernador, Intendente, Vice-intendente, Jefe de
        Gobierno o Vicejefe de Gobierno.
      </p>

      <p>
        d) Jefe de Gabinete de Ministros, Ministro o Secretario del Poder
        Ejecutivo de la Nación, o funcionario con rango equivalente dentro de la
        Administración Pública Nacional centralizada o descentralizada, su
        equivalente en las provincias o en la Ciudad Autónoma de Buenos Aires.
      </p>

      <p>
        e) Miembros del Poder Judicial de la Nación o del Ministerio Público de
        la Nación, con cargo no inferior a Juez o Fiscal de primera instancia,
        su equivalente en las provincias o en la Ciudad Autónoma de Buenos
        Aires.
      </p>

      <p>
        f) Defensor del Pueblo de la Nación, su equivalente en las provincias o
        en la Ciudad Autónoma de Buenos Aires y los adjuntos del Defensor del
        Pueblo.
      </p>

      <p>
        g) Interventor federal, o colaboradores del mismo con categoría no
        inferior a Secretario o su equivalente.
      </p>

      <p>
        h) Síndico General de la Nación o Síndico General Adjunto de la
        Sindicatura General de la Nación; Presidente o Auditor General de la
        Auditoría General de la Nación; máxima autoridad de un ente regulador o
        de los demás órganos que integran los sistemas de control del sector
        público nacional.
      </p>

      <p>
        i) Miembro del Consejo de la Magistratura de la Nación o del Jurado de
        Enjuiciamiento, su equivalente en las provincias o en la Ciudad Autónoma
        de Buenos Aires.
      </p>

      <p>j) Embajador o Cónsul.</p>

      <p>
        k) Máxima autoridad de las Fuerzas Armadas, de la Policía Federal
        Argentina, de Gendarmería Nacional, de la Prefectura Naval Argentina,
        del Servicio Penitenciario Federal o de la Policía de Seguridad
        Aeroportuaria, su equivalente en las provincias o en la Ciudad Autónoma
        de Buenos Aires.
      </p>

      <p>l) Rector o Decano de las Universidades Nacionales o provinciales.</p>

      <p>
        m) Máxima autoridad de un organismo estatal encargado de otorgar
        habilitaciones administrativas, permisos o concesiones, para el
        ejercicio de cualquier actividad; y de controlar el funcionamiento de
        dichas actividades o de ejercer cualquier otro control en virtud de un
        poder de policía.
      </p>

      <p>
        n) Máxima autoridad de los organismos de control de servicios públicos,
        nacional, provincial o de la Ciudad Autónoma de Buenos Aires.
      </p>

      <p>
        (Artículo sustituido por art. 2° de la Resolución N° 192/2024 de la
        Unidad de Información Financiera B.O. 10/12/2024. Vigencia: a partir de
        su publicación en el BOLETÍN OFICIAL.)
      </p>

      <p>ARTÍCULO 3º.- OTRAS PERSONAS EXPUESTAS POLÍTICAMENTE.</p>

      <p>
        Sin perjuicio de lo expuesto en los artículos precedentes, son,
        asimismo, consideradas Personas Expuestas Políticamente las siguientes:
      </p>

      <p>
        a) Autoridad, apoderado o candidato de partidos políticos o alianzas
        electorales, ya sea a nivel nacional o distrital, de conformidad con lo
        establecido en las Leyes Nros. 23.298 y 26.215.
      </p>

      <p>
        b) Autoridad de los órganos de dirección y administración de
        organizaciones sindicales.
      </p>

      <p>
        El alcance comprende a las personas humanas con capacidad de decisión,
        administración, control o disposición del patrimonio de la organización
        sindical.
      </p>

      <p>
        c) Autoridad, representante legal, integrante del órgano de
        administración o de la Comisión Directiva de las obras sociales
        contempladas en la Ley Nº 23.660.
      </p>

      <p>
        d) Las personas humanas con capacidad de decisión, administración,
        control o disposición del patrimonio de personas jurídicas privadas en
        los términos del artículo 148 del Código Civil y Comercial de la Nación,
        que reciban fondos públicos destinados a terceros y cuenten con poder de
        control y disposición respecto del destino de dichos fondos.
      </p>

      <p>
        (Artículo sustituido por art. 3° de la Resolución N° 192/2024 de la
        Unidad de Información Financiera B.O. 10/12/2024. Vigencia: a partir de
        su publicación en el BOLETÍN OFICIAL.)
      </p>

      <p>
        ARTÍCULO 4º.- PERSONAS EXPUESTAS POLÍTICAMENTE POR PARENTESCO O
        CERCANÍA.
      </p>

      <p>
        Se consideran Personas Expuestas Políticamente por parentesco o cercanía
        a aquellas que mantienen, con las individualizadas en los artículos 1° a
        3° de la presente, cualquiera de los siguientes vínculos:
      </p>

      <p>a) Cónyuge o conviviente.</p>

      <p>
        b) Padres/madres, hermanos/as, hijos/as, suegros/as, yernos/nueras,
        cuñados/as.
      </p>

      <p>
        c) Personas allegadas o cercanas: debe entenderse como tales a aquellas
        que mantengan relaciones jurídicas de negocios del tipo asociativas, aún
        de carácter informal, cualquiera fuese su naturaleza.
      </p>

      <p>
        d) Toda otra relación o vínculo que por sus características y en función
        de un análisis basado en riesgo, a criterio del Sujeto Obligado, pueda
        resultar relevante.
      </p>

      <p>
        (Artículo sustituido por art. 4° de la Resolución N° 192/2024 de la
        Unidad de Información Financiera B.O. 10/12/2024. Vigencia: a partir de
        su publicación en el BOLETÍN OFICIAL.)
      </p>

      <p>
        ARTÍCULO 5°.- MEDIDAS A ADOPTAR EN RELACIÓN CON LAS PERSONAS EXPUESTAS
        POLÍTICAMENTE.
      </p>

      <p>
        1) En los casos en que se tratase de Personas Expuestas Políticamente
        extranjeras (clientes o beneficiarios finales), además de realizar la
        debida diligencia continuada, cada Sujeto Obligado deberá:
      </p>

      <p>
        a) Obtener, de acuerdo con la normativa aplicable a cada Sujeto
        Obligado, la aprobación del Oficial de Cumplimiento, para iniciar las
        relaciones comerciales, o mantener las mismas con este tipo de clientes
        y sus beneficiarios finales en aquellos casos donde ya existe una
        relación comercial y modifican su condición de Persona Expuesta
        Políticamente.
      </p>

      <p>
        b) Adoptar las medidas razonables para poder establecer el origen de los
        fondos y del patrimonio.
      </p>

      <p>
        c) Adoptar las medidas de Debida Diligencia Reforzadas, que disponga la
        regulación específica vigente para cada Sujeto Obligado, en relación con
        este tipo de cliente y realizar el monitoreo continuado de la relación
        comercial.
      </p>

      <p>
        2) En los casos que se tratase de Personas Expuestas Políticamente
        nacionales, provinciales, municipales, de la Ciudad Autónoma de Buenos
        Aires o a las que se les haya encomendado una función de relevancia en
        una organización internacional (clientes o beneficiarios finales), que
        hayan sido calificados como clientes de riesgo alto, los Sujetos
        Obligados deberán cumplir con las medidas indicadas en los incisos a),
        b) y c) referidos precedentemente.
      </p>

      <p>
        Los requerimientos previstos en los puntos a) y b) descriptos
        precedentemente, serán aplicables a los vínculos de parentesco y a los
        allegados, según lo indicado en la presente resolución.
      </p>

      <p>
        ARTÍCULO 6°.- MANTENIMIENTO DE LA CONDICIÓN DE PERSONA EXPUESTA
        POLÍTICAMENTE.
      </p>

      <p>
        Las Personas Expuestas Políticamente, a la que aluden los artículos 1° a
        3° de la presente, mantendrán tal condición mientras ejerzan el cargo o
        desempeñen la función y hasta transcurridos DOS (2) años desde el cese
        en los mismos.
      </p>

      <p>
        Las Personas Expuestas Políticamente por parentesco o cercanía
        mantendrán su condición por el mismo tiempo que el de la persona con la
        que tienen o hayan tenido el vínculo.
      </p>

      <p>
        La Persona Expuesta Políticamente podrá informar el cese de su condición
        como tal a los Sujetos Obligados con los que opere como Cliente,
        detallando el motivo del cese. En tal caso, ello deberá ser tomado en
        cuenta y evaluado por el Sujeto Obligado a los fines previstos en el
        artículo 7° de la presente.
      </p>

      <p>
        (Artículo sustituido por art. 5° de la Resolución N° 192/2024 de la
        Unidad de Información Financiera B.O. 10/12/2024. Vigencia: a partir de
        su publicación en el BOLETÍN OFICIAL.)
      </p>

      <p>
        ARTÍCULO 7º.- ANÁLISIS DEL NIVEL DEL RIESGO Y MONITOREO DE PERSONAS
        EXPUESTAS POLÍTICAMENTE.
      </p>

      <p>
        Cada Sujeto Obligado deberá tomar medidas razonables para determinar si
        un cliente o beneficiario final es una Personas Expuesta Políticamente,
        al momento de iniciar o continuar con la relación comercial con estas, a
        cuyo efecto deberá contemplar -al menos- los siguientes parámetros:
      </p>

      <p>a) El objetivo y riesgo inherente de la relación comercial.</p>

      <p>b) Las características de las operaciones, considerando:</p>

      <p>
        1) La cuantía, naturaleza y complejidad de los productos o servicios
        comprendidos, canales de distribución, localización geográfica y países
        intervinientes en la operación u operaciones implicadas.
      </p>

      <p>
        2) El riesgo propio de las operaciones, como ser el uso de efectivo en
        forma intensiva, las transacciones de alto valor, la complejidad y
        diversidad de productos o servicios, el empleo de múltiples
        jurisdicciones, el uso de patrimonios de afectación y la dificultad de
        identificar al beneficiario final.
      </p>

      <p>3) El origen de los fondos u otros activos involucrados.</p>

      <p>c) Los actuales o potenciales conflictos de interés.</p>

      <p>
        d) La exposición a altos niveles de corrupción del ejercicio de la
        función pública de acuerdo con los antecedentes de esas actividades.
      </p>

      <p>
        Deberá asimismo tenerse en cuenta para el riesgo, el ejercicio de cargos
        sucesivos en la misma o diferente jurisdicción, su nivel jerárquico y
        relevancia de la persona que reúne la condición de Persona Expuesta
        Políticamente.
      </p>

      <p>
        En atención a lo expuesto, las Personas Expuestas Políticamente, serán
        objeto de medidas de debida diligencia, adecuadas y proporcionales al
        riesgo asociado y a la operatoria involucrada.
      </p>

      <p>
        En todos los casos tendrán que implementarse reglas de control de
        operaciones y alertas automatizadas, de modo que resulte posible
        monitorear, en forma intensa y continua, la ejecución de operaciones y
        su adecuación al perfil del cliente, su nivel de riesgo y las posibles
        desviaciones en éste.
      </p>

      <p>
        ARTÍCULO 8º.- DECLARACIÓN JURADA DE PERSONAS EXPUESTAS POLÍTICAMENTE.
      </p>

      <p>
        Los Sujetos Obligados enumerados en el artículo 20 de la Ley N° 25.246,
        deberán requerir a sus clientes, al momento de iniciar la relación
        contractual y al momento de modificar la condición de Persona Expuesta
        Políticamente (sea que empiece a revestir tal carácter o deje de serlo),
        que suscriban una declaración jurada en la que manifiesten si revisten o
        no dicha condición. A su vez, los clientes, deberán informar la
        condición de Persona Expuesta Políticamente de los beneficiarios
        finales, en caso de corresponder.
      </p>

      <p>
        La Persona Expuesta Políticamente podrá informar el cese de su condición
        como tal a los Sujetos Obligados con los que opere como Cliente,
        detallando el motivo del cese. En tal caso, ello deberá ser tomado en
        cuenta y evaluado por el Sujeto Obligado a los fines previstos en el
        artículo 7° de la presente.
      </p>

      <p>
        En forma previa a la firma de la declaración jurada de Persona Expuesta
        Políticamente, cada Sujeto Obligado deberá poner en conocimiento de su
        cliente el contenido de la presente Resolución a fin de que manifiesten
        si se encuentran incluidos en la nómina de personas establecidas en los
        artículos 1° a 4°.
      </p>

      <p>
        La suscripción de la declaración jurada de Persona Expuesta
        Políticamente podrá ser realizada tanto presencialmente o a través de
        medios electrónicos o digitales, dejando constancia de las evidencias
        correspondientes.
      </p>

      <p>
        (Artículo sustituido por art. 6° de la Resolución N° 192/2024 de la
        Unidad de Información Financiera B.O. 10/12/2024. Vigencia: a partir de
        su publicación en el BOLETÍN OFICIAL.)
      </p>

      <p>
        ARTÍCULO 9º.- VERIFICACIÓN DE LA CONDICIÓN DE PERSONAS EXPUESTAS
        POLÍTICAMENTE.
      </p>

      <p>
        Cada Sujeto Obligado deberá adoptar las medidas razonables que le
        permitan verificar, en todos los casos, la condición de Persona Expuesta
        Políticamente de sus clientes y beneficiarios finales de éstos.
      </p>

      <p>
        Podrán requerir información, o en su caso documentación, respecto de la
        actividad desarrollada por sus clientes, a efectos de determinar si el
        origen de los fondos involucrados en las operaciones se encuentra
        vinculado con el ejercicio de las funciones establecidas en los
        artículos 1° a 3° de la presente, o puedan provenir de una persona
        relacionada por parentesco o cercanía en los términos del artículo 4° de
        esta Resolución.
      </p>

      <p>
        La condición de Persona Expuesta Políticamente también podrá ser
        verificada mediante fuentes públicas de cualquier tipo, tales como las
        contenidas en boletines oficiales y registros, y por medio de fuentes
        privadas que por su reconocimiento y prestigio puedan brindar razonable
        certeza sobre la veracidad de su contenido (proveedores de información
        crediticia, servicios de validación de identidad, medios de prensa,
        entre otras).
      </p>

      <p>
        En todos los casos, los Sujetos Obligados deberán guardar las evidencias
        correspondientes de la verificación realizada.
      </p>

      <p>ARTÍCULO 10.- REQUERIMIENTOS ESPECIALES.</p>

      <p>
        Cuando se formulen Reportes de Operaciones Sospechosas por Lavado de
        Activos o por Financiación de Terrorismo donde se encuentren
        involucradas Personas Expuestas Políticamente, los Sujetos Obligados
        deberán dejar debida constancia de ello al efectuar la descripción de la
        operatoria.
      </p>

      <p>ARTÍCULO 11.- ENTRADA EN VIGENCIA Y DEROGACIÓN.</p>

      <p>
        La presente Resolución comenzará a regir a los TREINTA (30) días
        corridos de su publicación en el Boletín Oficial, fecha en la cual
        quedará derogada la Resolución UIF N° 134/18.
      </p>

      <p>ARTÍCULO 12.- APLICACIÓN TEMPORAL.</p>

      <p>
        Para los procedimientos sumariales que se encuentren en trámite a la
        fecha de la entrada en vigencia, o bien, para el análisis y supervisión
        de hechos, circunstancias y cumplimientos ocurridos con anterioridad a
        dicha fecha, se aplicará la Resolución UIF N° 134/2018.
      </p>

      <p>
        ARTÍCULO 13.- Comuníquese, publíquese, dese a la Dirección Nacional del
        Registro Oficial y archívese.
      </p>

      <p>Juan Carlos Otero</p>

      <p>e. 02/03/2023 N° 11672/23 v. 02/03/2023</p>
    </div>
  );
};

export default PEPText;
