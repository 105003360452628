import { createSlice } from "@reduxjs/toolkit";
import { Template } from "../types";

const initialState: Template = {
  onboardingType: null,
  formTitle: "",
  logo: "",
  steps: null,
  icon: "",
  preconfirmation: {
    steps: [],
    checkboxes: [],
  },
  favicon: "",
};

const activeTemplateSlice = createSlice({
  name: "activeTemplate",
  initialState,
  reducers: {
    onUpdateOnboardingType: (state, action) => {
      return { ...state, onboardingType: action.payload };
    },
    onUpdateActiveTemplate: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    onResetActiveTemplate: () => {
      return initialState;
    },
  },
});

export const {
  onUpdateOnboardingType,
  onUpdateActiveTemplate,
  onResetActiveTemplate,
} = activeTemplateSlice.actions;

export default activeTemplateSlice.reducer;
