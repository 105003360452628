import { doc, getFirestore, setDoc } from "@firebase/firestore";
import { FirestoreLivenessData } from "../interfaces/types";

export const saveLivenessData = ({
  gender,
  dni,
  email,
  attemptsDesktop,
  attemptsMobile,
  scannedQR,
  isValid,
  uuid,
  facialRecognition,
  faceLivenessDetection,
}: FirestoreLivenessData) => {
  const db = getFirestore();
  const livenessRef = doc(db, "livenessValidation", uuid);
  setDoc(
    livenessRef,
    {
      gender,
      dni,
      email,
      attemptsDesktop,
      attemptsMobile,
      scannedQR,
      isValid,
      facialRecognition,
      faceLivenessDetection,
    },
    { merge: true }
  );
};

export const livenessTexts = {
  waitingCameraPermissionText: "Esperando el acceso a tu cámara",
  startScreenBeginCheckText: "Comenzar captura",
  hintConnectingText: "Conectando...",
  hintVerifyingText: "Verificando...",
  hintCheckCompleteText: "Completado",
  goodFitCaptionText: "Mantené tu posición",
  goodFitAltText: "Encaja perfectamente en el óvalo",
  hintTooCloseText: "Alejate",
  hintTooFarText: "Acercate",
  hintCenterFaceText: "Centrá tu cara",
  hintFaceOffCenterText: "Centrá tu cara",
  hintMoveFaceFrontOfCameraText: "Movete enfrente de la cámara",
  hintTooManyFacesText: "Asegurate que haya solo una cara",
  hintFaceDetectedText: "Cara detectada",
  hintCanNotIdentifyText: "Movete enfrente de la cámara",
  hintIlluminationTooDarkText: "Busca un lugar con mejor iluminación",
  hintIlluminationTooBright: "Busca un lugar con menos luz",
  hintHoldFaceForFreshnessText: "Mantené tu posición",
  hintMatchIndicatorText: "50% completado. Acercate",
  tryAgainText: "Reintentar",
  retryCameraPermissionsText: "Reintentar",
  cameraNotFoundHeadingText: "Permití el acceso a tu cámara",
};
