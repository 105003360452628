import { useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import Container from "../Container/Container";
import { Button } from "@almafintech/react-components";

import NotFound from "../../assets/images/404.svg?react";

import styles from "./NotFoundPage.module.scss";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Layout hideSupportButton>
      <Container className={styles.container}>
        <NotFound />
        <h1>No pudimos encontrar la página</h1>
        <p>Escribiste una dirección inexistente o la cambiamos.</p>
        <Button text="Ir al inicio" onClick={() => navigate("/")} />
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
