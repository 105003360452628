import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialProps {
  qrImage: string;
  scannedQr: boolean;
  successMessage: string;
  onClose: () => void;
}

const initialState: InitialProps = {
  qrImage: "",
  scannedQr: false,
  successMessage: "",
  onClose: () => {},
};

const qrSlice = createSlice({
  name: "qr",
  initialState,
  reducers: {
    onUpdateQrImage: (state, action: PayloadAction<string>) => {
      return { ...state, qrImage: action.payload };
    },
    onUpdateScannedQr: (state, action: PayloadAction<boolean>) => {
      return { ...state, scannedQr: action.payload };
    },
    onUpdateSuccessMessage: (state, action: PayloadAction<string>) => {
      return { ...state, successMessage: action.payload };
    },
    onUpdateOnClose: (state, action: PayloadAction<() => void>) => {
      return { ...state, onClose: action.payload };
    },
  },
});

export const {
  onUpdateQrImage,
  onUpdateScannedQr,
  onUpdateSuccessMessage,
  onUpdateOnClose,
} = qrSlice.actions;

export default qrSlice.reducer;
