import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { currentIsSubstep } from "../components/TemplateInterpreter/utils";
import { useNavigate } from "react-router-dom";
import {
  onUpdateCompletedForm,
  onUpdateCompletedStep,
  onUpdateFormData,
  onUpdateLastCompletedSubStep,
  onUpdateReachedStep,
  onUpdateStep,
  onUpdateSubStep,
} from "../reducers/onboardingDataReducer";
import { genericToastifyError, isBasicStep } from "../utils";
import { onUpdateActiveTemplate } from "../reducers/activeTemplateReducer";
import { showToastMessage } from "@almafintech/react-components/ToastMessage";
import { FormikProps, FormikValues } from "formik";
import { loadingOnContinue } from "../reducers/buttonsReducer";
import * as Sentry from "@sentry/react";
import { showCustomToastifyError } from "../app/utils";
import { BLACKLIST_EMAILS } from "../constants";

const useForm = (formik?: FormikProps<FormikValues>) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const state = useAppSelector((state) => state);

  const { values, setValues } = formik || {};

  const {
    activeTemplate: template,
    onboardingData: {
      stepData: { step, subStep },
    },
    preconfirmation: { isEditingForm },
  } = state;

  // Get current step based on main step
  const currentStep = useMemo(
    () => template?.steps?.find((s) => s.step === step),
    [step, template?.steps]
  );

  // Check if current step has substeps
  const currentIsSubStep = currentIsSubstep(currentStep);

  // Get numeric template steps
  const numericTemplateSteps = template?.steps?.filter(
    ({ step: templateStep }) => typeof templateStep === "number"
  );

  // Check if current step is the last step
  const isLastStep = useMemo(() => {
    return (
      step ===
        template?.steps?.filter(({ step }) => typeof step === "number")
          .length &&
      (currentIsSubStep ? subStep === currentStep?.subSteps?.length : true)
    );
  }, [template?.steps, currentIsSubStep, currentStep, step, subStep]);

  // Unify steps and substeps
  const currentStepData = useMemo(() => {
    if (!isBasicStep(currentStep)) return currentStep;

    if ("subSteps" in currentStep) {
      return currentStep?.subSteps?.find((s) => s.step === (subStep || 1));
    } else {
      return currentStep;
    }
  }, [currentStep, subStep]);

  // Find previous step based on current step
  const findPreviousStep = () => {
    if (!numericTemplateSteps || !currentStep?.step) return null;
    const position = Number(currentStep.step) - 1;

    // Iterate backwards from the current step
    for (let index = position; index > 0; index--) {
      const element = numericTemplateSteps.find((s) => s.step === index);
      if (element)
        if (element.step === 1)
          return true; //This mean that the current step is TOKEN and we can go back
        else if (!element.disableBackNavigation) return element?.step;
    }

    return null;
  };

  // Handle Back Button
  const handleBack = () => {
    if (currentIsSubStep) {
      if (Number(subStep) > 1) {
        dispatch(onUpdateSubStep(Number(subStep) - 1));
      } else {
        const previousStep = findPreviousStep();
        if (previousStep) {
          dispatch(onUpdateStep(Number(previousStep)));
        }
      }
    } else {
      const previousStep = findPreviousStep();
      if (previousStep) dispatch(onUpdateStep(Number(previousStep)));
    }
  };

  // Handle Continue Button
  const handleContinue = async () => {
    if (!currentStepData || !values || !setValues) return;

    dispatch(onUpdateFormData(values));
    dispatch(onUpdateReachedStep(Number(step)));

    const isLastSubstep =
      Number(subStep) === Number(currentStep?.subSteps?.length);

    if ("onContinue" in currentStepData) {
      dispatch(loadingOnContinue(true));
      try {
        if (!currentStepData?.onContinue) return;
        // BLACKLIST
        if (BLACKLIST_EMAILS.includes(values.email)) {
          throw new Error();
          return;
        }

        const res = await currentStepData?.onContinue(values, dispatch, state);

        if (!currentIsSubStep || isLastSubstep) {
          dispatch(onUpdateCompletedStep(Number(step)));
        }

        if (res?.nextStep) {
          return dispatch(onUpdateStep(res.nextStep));
        }

        if (res?.newValuesToForm) {
          setValues((prevValues) => ({
            ...prevValues,
            ...res.newValuesToForm,
          }));
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        Sentry.captureException(error);
        if (["CXU_NOT_OWNER", "CXU_NOT_FOUND"].includes(error.message))
          showCustomToastifyError(error.message);
        else
          showToastMessage(genericToastifyError, {
            containerId: "formAlert",
            type: "error",
          });
        if (!currentIsSubStep || isLastSubstep) {
          dispatch(onUpdateCompletedStep(Number(step) - 1));
        }
        if (isLastSubstep) {
          dispatch(onUpdateLastCompletedSubStep(Number(subStep) - 1));
        }
        return;
      } finally {
        dispatch(loadingOnContinue(false));
      }
    }

    const continueToPreconfirmation = () => {
      dispatch(
        onUpdateActiveTemplate({
          onboardingType: template?.onboardingType,
          preconfirmation: template?.preconfirmation,
        })
      );
      dispatch(onUpdateCompletedForm(true));
      navigate(`/preconfirmacion?template=${template?.onboardingType}`);
      return;
    };

    if (currentIsSubStep) {
      if (Number(subStep) < Number(currentStep?.subSteps?.length)) {
        dispatch(onUpdateLastCompletedSubStep(Number(subStep)));
        dispatch(onUpdateSubStep(Number(subStep) + 1));
      } else {
        if (isEditingForm) continueToPreconfirmation();
        else {
          dispatch(onUpdateCompletedStep(Number(step)));
          dispatch(onUpdateStep(Number(step) + 1));
          dispatch(onUpdateReachedStep(Number(step) + 1));
          dispatch(onUpdateSubStep(1));
          dispatch(onUpdateLastCompletedSubStep(0));
        }
      }
    } else {
      if (isEditingForm) continueToPreconfirmation();
      else {
        dispatch(onUpdateStep(Number(step) + 1));
        dispatch(onUpdateSubStep(1));
      }
    }

    if (isLastStep) continueToPreconfirmation();
  };

  return {
    currentStep,
    currentIsSubStep,
    numericTemplateSteps,
    isLastStep,
    currentStepData,
    findPreviousStep,
    handleBack,
    handleContinue,
  };
};

export default useForm;
