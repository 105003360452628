const awsconfig = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id: import.meta.env.VITE_IDENTITY_POOL_ID, // This provides unauthenticated access
  aws_rekognition_region: "us-east-1",
  predictions: {
    identify: {
      entities: {
        region: "us-east-1",
      },
    },
  },
};

export default awsconfig;
