import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import modalReducer from "../reducers/modalReducer";
import onboardingDataReducer from "../reducers/onboardingDataReducer";
import activeTemplateReducer from "../reducers/activeTemplateReducer";
import authReducer from "../reducers/authReducer";
import livenessReducer from "../reducers/livenessReducer";
import buttonsReducer from "../reducers/buttonsReducer";
import renaperReducer from "../reducers/renaperReducer";
import validationsReducer from "../reducers/validationsReducer";
import qrReducer from "../reducers/qrReducer";
import infoStepReducer from "../reducers/infoStepReducer";
import preconfirmationReducer from "../reducers/preconfirmationReducer";
import * as Sentry from "@sentry/react";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["activeTemplate", "preconfirmation"],
};

const reducers = combineReducers({
  modal: modalReducer,
  onboardingData: onboardingDataReducer,
  activeTemplate: activeTemplateReducer,
  authentication: authReducer,
  liveness: livenessReducer,
  buttons: buttonsReducer,
  renaper: renaperReducer,
  validations: validationsReducer,
  qr: qrReducer,
  infoStep: infoStepReducer,
  preconfirmation: preconfirmationReducer,
});

const rootReducer = (
  state: ReturnType<typeof reducers> | undefined,
  action: Action<string>
) => {
  if (action.type === "reset-store") {
    // This will reset the state to its initial value
    return reducers(undefined, action);
  }

  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

//This is to log the store in Sentry
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //@redux/toolkit is offering extra protection for correct immutability and serialization of your state.
      //The previous configuration did not have this middleware
      //Solved those error with this lines
      immutableCheck: false,
      serializableCheck: false,
    }),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
