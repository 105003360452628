import { createSlice } from "@reduxjs/toolkit";
import { LivenessState } from "../components/LivenessValidationCard/interfaces/types";

interface InitialProps {
  livenessState: LivenessState;
  sessionId: string;
  externalCodeRecognition: string;
  externalCodeLiveness: string;
}

const initialState: InitialProps = {
  livenessState: null,
  sessionId: "",
  externalCodeRecognition: "",
  externalCodeLiveness: "",
};

const livenessSlice = createSlice({
  name: "liveness",
  initialState,
  reducers: {
    onUpdateSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    onUpdateLivenessState: (state, action) => {
      state.livenessState = action.payload;
    },
  },
});

export const { onUpdateSessionId, onUpdateLivenessState } =
  livenessSlice.actions;

export default livenessSlice.reducer;
