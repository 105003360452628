import { createSlice } from "@reduxjs/toolkit";
import { ButtonData } from "../types";

interface InitialProps {
  primaryButtonData: ButtonData | null;
  secondaryButtonData: ButtonData | null;
  tertiaryButtonData?: ButtonData | null;
}

const initialState: InitialProps = {
  primaryButtonData: null,
  secondaryButtonData: null,
  tertiaryButtonData: null,
};

const buttonsSlice = createSlice({
  name: "buttons",
  initialState,
  reducers: {
    onUpdatePrimaryButton: (state, action) => {
      state.primaryButtonData = {
        ...state.primaryButtonData,
        ...action.payload,
      };
    },
    onUpdateSecondaryButton: (state, action) => {
      state.secondaryButtonData = {
        ...state.secondaryButtonData,
        ...action.payload,
      };
    },
    onUpdateTertiaryButton: (state, action) => {
      state.tertiaryButtonData = {
        ...state.tertiaryButtonData,
        ...action.payload,
      };
    },
    loadingOnContinue: (state, action) => {
      if (
        !state.primaryButtonData ||
        !state.secondaryButtonData ||
        !state.tertiaryButtonData
      ) {
        return state;
      }

      const loadingOrDisable = action.payload;

      return {
        ...state,
        primaryButtonData: {
          ...state.primaryButtonData,
          isLoading: loadingOrDisable,
        },
        secondaryButtonData: {
          ...state.secondaryButtonData,
          disabled: loadingOrDisable,
        },
        tertiaryButtonData: {
          ...state.tertiaryButtonData,
          disabled: loadingOrDisable,
        },
      };
    },
  },
});

export const {
  onUpdatePrimaryButton,
  onUpdateSecondaryButton,
  onUpdateTertiaryButton,
  loadingOnContinue,
} = buttonsSlice.actions;

export default buttonsSlice.reducer;
