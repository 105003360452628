import { ChangeEvent } from "react";
import errorIcon from "../../assets/images/icons/alert/error.svg";
import styles from "./SegmentedOptionsInput.module.scss";
import { SegmentedInputOption } from "../../types";

interface Props {
  name: string;
  label?: string;
  options: SegmentedInputOption[];
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isInvalid?: boolean;
  errorMessage?: string;
}

const SegmentedOptionsInput = ({
  name,
  options,
  label,
  value,
  isInvalid,
  onChange,
  errorMessage,
}: Props) => {
  const {
    label: labelStyle,
    container,
    option: optionStyle,
    active,
    error,
  } = styles;

  return (
    <div className="w-full">
      {label && <span className={labelStyle}>{label}</span>}
      <fieldset className={container}>
        {options.map((option) => (
          <div
            key={option.label}
            className={`${optionStyle} ${option.value === value ? active : ""}`}
          >
            <input
              type="radio"
              {...option}
              key={option.value}
              name={name}
              onChange={onChange}
            />
            <label>{option.label}</label>
          </div>
        ))}
      </fieldset>
      {isInvalid && (
        <div className="flex gap-2">
          <img src={errorIcon} alt="error" />
          <span className={error}>
            {errorMessage ?? "Este campo es obligatorio"}
          </span>
        </div>
      )}
    </div>
  );
};

export default SegmentedOptionsInput;
