import { BasicStep, ComponentStep, Step } from "../types";
import logoAllariaMas from "../assets/images/logos/allariamas.svg";
import logoAllaria from "../assets/images/logos/allaria.svg";
import faviconAllariaMas from "../assets/favicon.png";
import faviconAllaria from "../assets/faviconAllaria.png";
import { FormikValues } from "formik";

// Function to check if a Step is a BasicStep
export const isBasicStep = (
  step?: Step | BasicStep | ComponentStep
): step is BasicStep => {
  // Check if step is defined and does not have a component property
  return step !== undefined && ("fields" in step || "subSteps" in step);
};

// Function to check if a Step is a ComponentStep
export const isComponentStep = (
  step?: Step | BasicStep | ComponentStep
): step is ComponentStep => {
  // Check if step is defined and has a component property
  return step !== undefined && "component" in step;
};

export const getCompanyData = () => {
  const company = import.meta.env.VITE_COMPANY as "ALLARIA" | "ALLARIAMAS";
  const staticUrl = import.meta.env.VITE_BASE_URL_STATIC;

  switch (company) {
    case "ALLARIA":
      return {
        formTitle: "Onboarding Allaria",
        logo: logoAllaria,
        favicon: faviconAllaria,
        companyName: company,
        termsAndConditions: [
          {
            name: "termsAndConditions",
            required: true,
            label: (
              <div>
                Acepto los{" "}
                <span id="terms-and-conditions">Términos y Condiciones</span>
              </div>
            ),
            links: [
              {
                id: "terms-and-conditions",
                name: "Términos y Condiciones de Allaria",
                url: `${staticUrl}/terms/T%26C+Allaria+con+A%2B.pdf`,
              },
            ],
          },
          {
            required: false,
            name: "senebiAndExteriorNotes",
            label: (
              <div>
                Acepto la <span id="senebi-note">Nota SENEBI</span> y{" "}
                <span id="exterior-note">Nota exterior</span>
              </div>
            ),
            links: [
              {
                id: "senebi-note",
                name: "Nota SENEBI",
                url: `${staticUrl}/terms/Nota+SENEBI.pdf`,
              },
              {
                id: "exterior-note",
                name: "Nota exterior",
                url: `${staticUrl}/terms/Nota+de+operaciones+en+el+exterior.pdf`,
              },
            ],
          },
        ],
        phone: "5491140411111",
        mail: "atencionalcliente@allaria.com.ar",
      };
    case "ALLARIAMAS":
      return {
        formTitle: "Onboarding Allaria+",
        logo: logoAllariaMas,
        favicon: faviconAllariaMas,
        companyName: company,
        termsAndConditions: [
          {
            required: true,
            name: "termsAndConditions",
            label: (
              <div>
                Acepto los{" "}
                <span id="terms-and-conditions">Términos y Condiciones</span>
              </div>
            ),
            links: [
              {
                id: "terms-and-conditions",
                name: "Términos y Condiciones de Allaria+",
                url: `${staticUrl}/terms/2)+TyC+Allaria%2B+Cuenta+%2B+Anexo+I+sin+aclaracion+(web+y+OB+Allaria%2B).pdf`,
              },
            ],
          },
        ],
        phone: "5491138499171",
        mail: "soporte@allariamas.com.ar",
      };

    default:
      return {
        formTitle: "Onboarding Allaria+",
        logo: logoAllariaMas,
        favicon: faviconAllariaMas,
        companyName: company,
        termsAndConditions: [
          {
            required: true,
            name: "termsAndConditions",
            label: (
              <div>
                Acepto los{" "}
                <span id="terms-and-conditions">Términos y Condiciones</span> y{" "}
                <span id="endorsed-instruments">
                  Nota de instrumentos avalados
                </span>
              </div>
            ),
            links: [
              {
                id: "terms-and-conditions",
                name: "Términos y Condiciones de Allaria+",
                url: `${staticUrl}/TyC+Allaria%2B+Cuenta+con+Anexo+I+sin+firma.pdf`,
              },
            ],
          },
        ],
        phone: "5491138499171",
        mail: "soporte@allariamas.com.ar",
      };
  }
};

// Variables for support contact
export const PHONE_NUMBER = getCompanyData().phone;
export const WHATSAPP_LINK = `https://wa.me/${PHONE_NUMBER}`;
export const MAIL = getCompanyData().mail;

// Generic content to show for toastify error messages
export const genericToastifyError = (
  <div>
    Lo sentimos, algo falló inesperadamente. Intentalo nuevamente y si el error
    persiste, contactate con{" "}
    <a href={WHATSAPP_LINK} target="_blank" rel="noreferrer">
      <b>Soporte.</b>
    </a>
  </div>
);

export const customToastifyError = (message: string) => {
  return <div>{message}</div>;
};

// Styles for QR Image
export const qrStyle = {
  color: {
    dark: "#224288",
  },
  margin: 0,
  width: 200,
};

export const validDniTypes = [
  "image/jpeg",
  "image/png",
  "image/heic",
  "image/heif",
  "application/pdf",
];

export const maxFileSize = 10; // MB

/**
 * Convert a string value to a CUIT format
 * @param {string} value - The value to be converted to CUIT format
 * @returns {string} - The value in CUIT format
 */
export const asCuit = (value: string) =>
  value.slice(0, 2) + "-" + value.slice(2, 10) + "-" + value.slice(10, 11);

export const cities = [
  "Provincia de Buenos Aires",
  "CABA",
  "Catamarca",
  "Chaco",
  "Chubut",
  "Córdoba",
  "Corrientes",
  "Entre Ríos",
  "Formosa",
  "Jujuy",
  "La Pampa",
  "La Rioja",
  "Mendoza",
  "Misiones",
  "Neuquén",
  "Río Negro",
  "Salta",
  "San Juan",
  "San Luis",
  "Santa Cruz",
  "Santa Fe",
  "Santiago del Estero",
  "Tierra del Fuego",
  "Tucumán",
];

export const cityOptions = () =>
  cities.map((city) => ({ value: city, label: city }));

//Backend not support "Ciudad Autónoma de Buenos Aires", so we parse it to "CABA"
export const parseStateAddress = (state: string) => {
  if (state === "Ciudad Autónoma de Buenos Aires") return "CABA";
  if (
    ["provincia de buenos aires", "buenos aires", "bs as"].includes(
      state.toLowerCase()
    )
  )
    return "Provincia de Buenos Aires";
  else return state;
};

export const isToros = (formValues: FormikValues | null) => {
  if (!formValues) return false;

  return formValues?.referrer === "Toros Capital";
};

export const isWina = (formValues: FormikValues | null) => {
  if (!formValues) return false;

  return formValues?.referrer === "Wina";
};
