import styles from "./InteractiveInformation.module.scss";
import { Button, ToastMessage } from "@almafintech/react-components";
import InfoIcon from "../../assets/images/icons/alert/info.svg";
import { useEffect, useState } from "react";
import { logAnalyticsAndHotjarEvent } from "../../main";
import { useAppSelector } from "../../app/hooks";

interface Button {
  text: string;
  onClick: () => void | Promise<void>;
}
interface InformationProps {
  primaryButton: Button;
  secondaryButton?: Button;
  title: string;
  subtitle: string;
}
const InteractiveInformation = ({
  primaryButton,
  secondaryButton,
  title,
  subtitle,
}: InformationProps) => {
  const [isLoading, setIsLoading] = useState({
    primaryButton: false,
    secondaryButton: false,
  });
  const { type } = useAppSelector((state) => state.infoStep);

  useEffect(() => {
    if (type === "PENDING_REGISTRY_LOCAL" || type === "PENDING_REGISTRY_REMOTE")
      logAnalyticsAndHotjarEvent("modal_returning_user_displayed");
  }, []);

  const onSecondaryButtonClick = async () => {
    setIsLoading({ ...isLoading, secondaryButton: true });
    secondaryButton && (await secondaryButton.onClick());
    setIsLoading({ ...isLoading, secondaryButton: false });
  };

  const onPrimaryButtonClick = async () => {
    setIsLoading({ ...isLoading, primaryButton: true });
    await primaryButton.onClick();
    setIsLoading({ ...isLoading, primaryButton: false });
  };
  const {
    informationContainer,
    modalAlert,
    primaryButton: primaryButtonStyle,
    secondaryButton: secondaryButtonStyle,
  } = styles;
  return (
    <div className={informationContainer}>
      <ToastMessage
        messageId="informationAlert"
        position="top-center"
        className={modalAlert}
      />

      <img src={InfoIcon} />
      <h1>{title}</h1>
      <p>{subtitle}</p>
      <div>
        {secondaryButton?.text && (
          <Button
            className={secondaryButtonStyle}
            onClick={onSecondaryButtonClick}
            text={secondaryButton?.text}
            variant="secondary"
            isLoading={isLoading.primaryButton || isLoading.secondaryButton}
            disabled={isLoading.secondaryButton}
          />
        )}
        <Button
          className={primaryButtonStyle}
          onClick={onPrimaryButtonClick}
          text={primaryButton.text}
          disabled={isLoading.primaryButton || isLoading.secondaryButton}
          isLoading={isLoading.primaryButton}
        />
      </div>
    </div>
  );
};

export default InteractiveInformation;
