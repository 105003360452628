import { createSlice } from "@reduxjs/toolkit";

interface AuthReducer {
  token: string | null;
  signatureRequestId: number | null;
}
const initialState: AuthReducer = {
  token: null,
  signatureRequestId: null,
};

const authSlice = createSlice({
  name: "authentications",
  initialState,
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    updateSignatureRequestId: (state, action) => {
      state.signatureRequestId = action.payload;
    },
  },
});

export const { updateToken, updateSignatureRequestId } = authSlice.actions;

export default authSlice.reducer;
