//Redux
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeModal } from "../../reducers/modalReducer";
//Components
import { Modal } from "@almafintech/react-components/Modal";
import { Button } from "@almafintech/react-components/Button";
//Images
import successIcon from "../../assets/images/icons/alert/success.svg";
//Styles
import styles from "./QRModal.module.scss";

const QRModal = () => {
  const { container, header, success, qr, button } = styles;

  const dispatch = useAppDispatch();

  const { modalName } = useAppSelector((state) => state.modal);
  const { qrImage, scannedQr, successMessage, onClose } = useAppSelector(
    (state) => state.qr
  );

  const handleClose = () => {
    onClose()
    dispatch(closeModal());
  };

  return (
    <Modal
      isOpen={modalName === "modal-qr"}
      onClose={handleClose}
      hideCloseButton={scannedQr}
      className={container}
      header={
        !scannedQr && (
          <header className={header}>
            <h1>Código QR</h1>
            <span>Escaneá el código para continuar en tu teléfono.</span>
          </header>
        )
      }
      footer={
        <Button
          text={scannedQr ? "Entendido" : "Cerrar"}
          variant="primary"
          className={`${button} ${scannedQr ? "mb-2" : ""}`}
          onClick={handleClose}
        />
      }
      centerFooter
    >
      <section className={container}>
        {scannedQr ? (
          <div className={success}>
            <img src={successIcon} alt="successIcon" />
            <h3>Mantené la página abierta</h3>
            <span>{successMessage}</span>
          </div>
        ) : (
          <div className={qr}>
            <img src={qrImage} alt="QR" />
          </div>
        )}
      </section>
    </Modal>
  );
};

export default QRModal;
