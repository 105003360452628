import { ReactNode } from "react";
import styles from "./Container.module.scss";
const Container = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const { container } = styles;
  return <section className={`${container} ${className}`}>{children}</section>;
};

export default Container;
